import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CheckIcon from '../../assets/icons/check_icon.svg';
import { countriesInfo } from '../../config/countriesFlag';
import { useConfig } from '../../config/queries';
import { getCookie } from '../../utils/cookies';
import styles from './country.module.css';

interface IProps {
  bordered?: boolean;
  short?: boolean;
}

export const CountryWelcome = ({ bordered, short }: IProps) => {
  const [userCountry, changeUserCountry] = useState<string>();
  useEffect(() => {
    if (!userCountry) {
      changeUserCountry(getCookie('user_country'));
    }
  });
  const { data: config, isLoading } = useConfig();
  const userFlag = get(countriesInfo, [userCountry, 'emoji'], '');
  const countryName = get(countriesInfo, [userCountry, 'name']);

  if (
    isLoading ||
    !config.restrictedCountries.includes(userCountry) ||
    !countryName ||
    typeof window === 'undefined'
  ) {
    return <></>;
  }
  if (short) {
    return (
      <div>
        {userFlag}&#160;
        <FormattedMessage
          defaultMessage="Players from <b>{countryName}</b> are accepted"
          values={{ countryName, userFlag }}
        />
      </div>
    );
  }

  if (bordered) {
    return (
      <div className={styles.country_accept}>
        <span>
          {userFlag}&#160;
          <FormattedMessage
            defaultMessage="Players from <b>{countryName}</b> are accepted"
            values={{ countryName, userFlag }}
          />
        </span>
        <CheckIcon />
      </div>
    );
  }

  return (
    <p className={styles.countryWelcome}>
      {userFlag}&#160;
      <FormattedMessage
        defaultMessage="Players from {country} are accepted Welcome bonus: 100% up to $500"
        values={{ country: countryName }}
      />
    </p>
  );
};

import { get } from 'lodash';
import { useRouter } from 'next/router';
import { MyImage } from '../MyImage';
import { useConfig } from '../../config/queries';
import { Casino } from '../../types/Casino';
import BadgeIcon from '../../assets/icons/badge.svg';

import styles from './tags.module.css';

export const Tags = ({ casino }: { casino: Casino }) => {
  const { locale } = useRouter();
  const { data, isLoading } = useConfig();
  const emojiCategories = casino?.categories.filter((category) =>
    data?.emojiCasinoCategoryIds.includes(category.id),
  );

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={styles.tags}>
      {emojiCategories?.map((category) => {
        return (
          <div key={category.id} className={styles.tag}>
            <BadgeIcon fill={category.color} />
            {category.img && (
              <MyImage
                visibleByDefault={true}
                src={category.img}
                height={24}
                width={24}
                alt={get(
                  category,
                  ['translations', locale, 'name'],
                  'Category tag',
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

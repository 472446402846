import clsx from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import styles from './Raiting.module.css';

const raiting = new Array(5).fill('');
enum RaitSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

const Raiting = ({
  currentRaiting = 0,
  revert,
  size = RaitSize.md,
}: {
  currentRaiting: number;
  revert?: boolean;
  size?: RaitSize;
  medium?: boolean;
}) => (
  <span
    className={clsx(styles.raiting, {
      [styles.revert]: revert,
      [styles.extraSmallText]: size === RaitSize.xs,
      [styles.smallText]: size === RaitSize.sm,
      [styles.mediumText]: size === RaitSize.md,
    })}
  >
    <span>
      {raiting.map((e, index) => (
        <span
          key={index}
          className={
            index < currentRaiting
              ? styles.activeRaitingItem
              : styles.raitingItem
          }
        >
          🌶
        </span>
      ))}
    </span>
    <span
      className={clsx(styles.raitingNumber, { [styles.rightMargin]: revert })}
    >
      {currentRaiting.toFixed(1)}
    </span>
  </span>
);

const SelectRaiting = ({
  rait,
  onChange,
}: {
  rait: number;
  onChange: Dispatch<SetStateAction<number>>;
}) => {
  const [hover, setHover] = useState(0);
  const [touch, setTouch] = useState(0);
  const [baseRait, setBaseRait] = useState(rait);
  const handleTouchMove = (e) => {
    const diff = e.touches[0].clientX - touch;
    let stars = Math.floor(diff / 36);
    if (stars < 0) {
      stars++;
    }
    let newRait: number = baseRait + stars;
    if (newRait > 5) {
      newRait = 5;
    }
    if (newRait < 1) {
      newRait = 1;
    }
    onChange(newRait);
  };
  const handleTouchStart = (e) => {
    setBaseRait(rait);
    setTouch(e.touches[0].clientX);
  };

  return (
    <span
      className={styles.raiting}
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <span onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
        {raiting.map((e, index) => (
          <span
            key={index}
            className={clsx(
              styles.selectRait,
              index < rait ? styles.activeRaitingItem : styles.raitingItem,
            )}
            onClick={() => onChange(index + 1)}
            onMouseEnter={() => onChange(index + 1)}
            // onMouseLeave={() => onChange(hover)}
          >
            🌶
          </span>
        ))}
      </span>
      <span className={styles.selectedRaitingNumber}>{rait.toFixed(1)}</span>
    </span>
  );
};

export { Raiting, SelectRaiting, RaitSize };

import { omit, get } from 'lodash';
import { stringify } from 'qs';
import { useInfiniteQuery, useQuery } from 'react-query';
import { AwardTx } from '../../types/Award';
import { Casino, CasinoResponse } from '../../types/Casino';
import { CategoryTx } from '../../types/Category';
import { ListResponse } from '../../types/common';
import { OptionTx } from '../../types/Option';
import { JockerApi } from '../api';

const normalizeCasino = (casino: CasinoResponse): Casino => {
  return {
    ...casino,
    //@ts-ignore
    options: casino.options.reduce(
      (acc, opt) => {
        const slug = opt.translations.find(
          (el) => el.languageCode === 'en',
        ).slug;
        const translations = opt.translations.reduce(
          (acc, translation) => ({
            ...acc,
            [translation.languageCode]: translation,
          }),
          {},
        );
        return {
          ...acc,
          [slug]: translations,
        };
      },
      {
        depositLimit: {
          ...casino.translations.reduce(
            (acc, item) => ({
              ...acc,
              [item.languageCode]: {
                values: [{ name: '', withdrawalLimits: item.withdrawalLimits }],
              },
            }),
            {},
          ),
        } as Record<string, OptionTx>,
        depositTime: {
          ...casino.translations.reduce(
            (acc, item) => ({
              ...acc,
              [item.languageCode]: { values: [{ name: item.withdrawalTime }] },
            }),
            {},
          ),
        } as Record<string, OptionTx>,
      },
    ),
    awards: casino.awards.map((opt) => {
      const translations = opt.translations.reduce(
        (acc, translation) => ({
          ...acc,
          [translation.languageCode]: translation,
        }),
        {} as Record<string, AwardTx>,
      );
      return {
        ...opt,
        translations,
      };
    }),
    categories: casino.categories.map((category) => ({
      ...category,
      translations: category.translations.reduce(
        (acc, translation) => ({
          ...acc,
          [translation.languageCode]: translation,
        }),
        {} as Record<string, CategoryTx>,
      ),
    })),
    translations: casino.translations.reduce(
      (acc, translation) => ({
        ...acc,
        [translation.languageCode]: {
          ...translation,
          text: translation.text
            .replace('code', 'p')
            .replace('<pre>', '')
            .replace('</pre>', ''),
        },
      }),
      {},
    ),
  };
};

const defaultPaginationParams = {
  perPage: 10,
};

export const fetchCasinosList = async (
  { isLoadMore, ...props }: Record<string | number, string | string[] | boolean>,
  pageParam: number,
) => {
  const params = {
    ...defaultPaginationParams,
    ...props,
    currentPage: isLoadMore ? pageParam + 1 : props.currentPage,
  };
  const { data } = await JockerApi.get<ListResponse<CasinoResponse>>(
    `casinos/public-list?${stringify(params, { indices: false })}`,
  );
  const normalizedCasinos = data.data.map(normalizeCasino);
  return { ...data, data: normalizedCasinos } as ListResponse<Casino>;
};

const fetchCasinoById = async (id: string) => {
  const { data } = await JockerApi.get<CasinoResponse>(
    `casinos/public?id=${id}`,
  );
  return normalizeCasino(data);
};

const fetchCasinoBySlug = async (slug: string) => {
    const { data } = await JockerApi.get<CasinoResponse>(
        `casinos/public?slug=${slug}`,
    );
    return normalizeCasino(data);
};

export const useCasinosListInfinity = (
  params: Record<string, string | string[] | boolean>,
) =>
  useInfiniteQuery(
    ['casinos-list-infinity', omit(params, 'isLoadMore')],
    ({ pageParam = 0 }) => fetchCasinosList(params, pageParam),
    {
      staleTime: Infinity,
      getPreviousPageParam: (firstPage) =>
        firstPage.pagination.currentPage ?? null,
      getNextPageParam: (lastPage) => lastPage.pagination.currentPage ?? null,
    },
  );

export const useCasinosList = (
  params: Record<string, string | string[] | boolean>,
) =>
  useQuery(
    ['casinos-list', params],
    ({ pageParam = 0 }) => fetchCasinosList(params, pageParam),
    {},
  );

export const useCasino = (slug: number | string) =>
  useQuery(['casino', String(slug)], () => fetchCasinoBySlug(String(slug)), {
    staleTime: Infinity,
  });

export const fetchCasino = async (queryClient, slug: string) =>
    queryClient.fetchQuery({
        queryKey: ['casino', String(slug)],
        queryFn: () => fetchCasinoBySlug(slug),
    });

// SSR - prefetching
export const prefetchCasino = async (queryClient, slug: string) => {
  await queryClient.prefetchQuery({
    queryKey: ['casino', String(slug)],
    queryFn: () => fetchCasinoBySlug(slug),
  });
};

export const prefetchCasinos = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchQuery({
    queryKey: ['casinos-list', params],
    queryFn: () => fetchCasinosList(params, 0),
  });
  await queryClient.setQueryData(['casinos-list', params], (data) => {
    return {
      ...data,
      pageParams: [],
    };
  });
};

export const prefetchCasinosInifnity = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: ['casinos-list-infinity', omit(params, 'isLoadMore')],
    queryFn: () => fetchCasinosList(params, 0),
  });
};

import axios from 'axios';
import { serialize } from 'cookie';
import { dehydrate, QueryClient } from 'react-query';
import { prefetchConfig } from './queries';

const getUserCountry = async (req) => {
  const forwarded = req.headers['x-forwarded-for'];
  let ip =
    typeof forwarded === 'string'
      ? forwarded.split(/, /)[0]
      : req.socket.remoteAddress;
  if (ip === '::1') {
    ip = '79.110.129.136';
  }
  const userInfoResponse = await axios.get(
    `http://www.geoplugin.net/json.gp?ip=${ip}`,
  );
  return userInfoResponse.data.geoplugin_countryCode;
};

export const getServerProps = async ({ req, res, queryClient }) => {
  if (!req?.cookies?.user_country) {
    const country = await getUserCountry(req);
    const cookie = serialize('user_country', country, {
      path: '/',
    });
    res.setHeader('Set-Cookie', cookie);
  }
  await prefetchConfig(queryClient);
};

export default async function getServerSideProps({ req, res }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
      },
    },
  });
  if (!req.cookies.user_country) {
    const country = await getUserCountry(req);
    const cookie = serialize('user_country', country, {
      path: '/',
    });
    res.setHeader('Set-Cookie', cookie);
  }

  await prefetchConfig(queryClient);

  return {
    props: {
      host: req.headers.host || null,
      dehydratedState: dehydrate(queryClient),
    },
  };
}

import clsx from 'clsx';
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Casino, CasinoTx } from '../../types/Casino';
import { Raiting } from '../Raiting';
import styles from './Casino.module.css';
import { MyImage } from '../MyImage';
import { CountryWelcome } from '../CountryWelcome';
import { Tags } from '../Tags';
import { useConfig } from '../../config/queries';

type TagType = 'new' | 'top-rated' | 'hot';

interface IProps {
  withoutButton?: boolean;
  withoutCountryInfo?: boolean;
}

const CasinosCard = ({
  withoutCountryInfo,
  withoutButton,
  ...casino
}: Casino & IProps) => {
  const {
    data: { rateField },
  } = useConfig();
  const { locale, push } = useRouter();
  const enValues = get(casino, ['translations', 'en']);
  const languageValues: CasinoTx = get(
    casino,
    ['translations', locale],
    enValues,
  );
  const handleClickCasino = () => {
    push(`/casinos/${languageValues.slug}`);
  };
  return (
    <div onClick={handleClickCasino} className={styles.casino}>
      <div className={styles.casinoHeader}>
        <div className={clsx(styles.casinoImage)}>
          {languageValues.logotype ? (
            <MyImage
              className={styles.logo}
              src={languageValues.logotype}
              width={52}
              height={52}
              alt={languageValues.name || 'Casino small image'}
            />
          ) : (
            <div className={styles.imagePlaceholder} />
          )}
        </div>
        <div className={styles.casinoMainInfo}>
          <span className={clsx(styles.bold, styles.name)}>
            {languageValues.name}
          </span>
          <Raiting
            currentRaiting={
              rateField === 'adminRate' ? casino.adminRate : casino.rate
            }
            medium
          />
        </div>
        <Tags casino={casino} />
      </div>
      <div className={styles.casinoInfo}>
        {!withoutCountryInfo && <CountryWelcome short />}
        {casino.welcomeBonus && (
          <div>
            <FormattedMessage defaultMessage="Welcome bonus:" />
            <b>&#160;{casino.welcomeBonus}</b>
          </div>
        )}
        <div>
          <FormattedMessage defaultMessage="Active bonuses: " />
          <b className={styles.red}>&#160; {languageValues.bonusesCount}</b>
        </div>
        {!withoutButton && (
          <div className={styles.buttons}>
            <Link href={`/casinos/${languageValues.slug}`}>
              <a className={styles.review}>
                <FormattedMessage defaultMessage="Read more" />
              </a>
            </Link>
            <Link href={`${languageValues.url || ''}`}>
              <a className={styles.casino_btn}>
                <FormattedMessage defaultMessage="Play Hard" />
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export { CasinosCard };
export type { TagType };

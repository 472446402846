import clsx from 'clsx';
import { get } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Casino, CasinoTx } from '../../types/Casino';
import { Raiting, RaitSize } from '../Raiting';
import { Tags } from '../Tags';
import { MyImage } from '../MyImage';
import styles from './CasinosSmall.module.css';
import { useConfig } from '../../config/queries';

const LinkWrapper = ({ link, children }: { link?: string; children: any }) => {
  if (link) {
    return (
      <Link href={link}>
        <a className={styles.casino}>{children}</a>
      </Link>
    );
  }
  return <div className={styles.casino}>{children}</div>;
};

const CasinosCardSmall = ({
  casinoData,
  casinoTx,
  smallImg,
  link,
  small,
}: {
  casinoData: Casino;
  casinoTx: CasinoTx;
  link?: string;
  smallImg?: boolean;
  small?: boolean;
}) => {
  const {
    data: { rateField },
  } = useConfig();
  return (
    <LinkWrapper link={link}>
      <div
        className={clsx(styles.casinoImage, {
          [styles.casinoImageSmall]: smallImg,
        })}
      >
        {casinoTx.logotype ? (
          <MyImage
            src={casinoTx.logotype}
            width={smallImg ? 48 : 52}
            height={smallImg ? 48 : 52}
            alt={casinoTx.name || 'Casino image'}
            className={styles.img}
          />
        ) : (
          <div className={styles.imagePlaceholder} />
        )}
      </div>
      <div
        className={clsx(styles.casinoMainInfo, {
          [styles.casinoMainInfoSmall]: smallImg,
        })}
      >
        <span className={clsx(styles.bold, styles.name)}>{casinoTx.name}</span>
        <Raiting
          currentRaiting={
            rateField === 'adminRate' ? casinoData?.adminRate : casinoData?.rate
          }
          size={small ? RaitSize.sm : RaitSize.md}
        />
      </div>
      <Tags casino={casinoData} />
    </LinkWrapper>
  );
};

const CasinosCardSmallForHomePage = (casino: Casino) => {
  const { locale } = useRouter();
  const casinoTx = get(casino, ['translations', locale]);
  return (
    <div className={styles.wrapperForHomePage}>
      <CasinosCardSmall
        casinoData={casino}
        casinoTx={casinoTx}
        smallImg
        link={`/casinos/${casinoTx.slug}`}
        small
      />
    </div>
  );
};

export { CasinosCardSmall, CasinosCardSmallForHomePage };
